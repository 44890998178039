import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [date, setDate] = useState('');
  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();  
  }, []);

  return (
    <main>
      <h3>Coming Soon...</h3>
      <h1>Radium QC</h1>
      <br />
      <h2>Current Date:</h2>
      <p>{date ? date : 'Loading date...'}</p>
    </main>
  );
}

export default App;
